import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/nodejs-memory-management",
  "date": "2020-02-21",
  "title": "Node.js Memory Management",
  "author": "admin",
  "tags": ["development", "javascript", "node.js"],
  "featuredImage": "feature.jpg",
  "excerpt": "Node.js memory management provides means to dynamically allocate memory chunks for programs when they request them and free it when they are no longer needed, so they can be reused. Application-level memory management can be manual or automatic."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Heap`}</h2>
    <p>{`The memory space managed by Node is called `}<strong parentName="p">{`Heap`}</strong>{`.
Heap is divided into several different spaces called `}<strong parentName="p">{`Generations`}</strong>{`.`}</p>
    <h3>{`New Space`}</h3>
    <p>{`New Space is where objects are first created. It is designed to be small and fast to garbage collect.`}</p>
    <p>{`Memory in a new space is managed by a `}<strong parentName="p">{`pointer`}</strong>{`. When a new space is created the pointer moves to the next free spot.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2020-02-21-nodejs-memory-management/BC6DED4D-075E-4885-8193-F136B162BE3D.png",
        "alt": "New space"
      }}></img></p>
    <p>{`When the pointer reaches the end of the space, it goes into a `}<em parentName="p">{`Scavenge`}</em>{` operation to identify all of the objects in memory that no longer referenced.`}</p>
    <p>{`Then it removes these, unpacks the memory and re-allocate the pointer to the next available free spot.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2020-02-21-nodejs-memory-management/C08C9569-E8FC-4F5F-8331-2EE4F9F6EAA9.png",
        "alt": "Next free spot"
      }}></img></p>
    <h3>{`Old Space`}</h3>
    <p>{`Objects that survive ~two garbage collection operations~ are promoted into the old space.`}</p>
    <p>{`Old space is much larger than the new space, so it implements a different garbage collection algorithm - `}<em parentName="p">{`Mark-sweep`}</em></p>
    <p>{`The GC marks the objects being referenced. Anything not marked is unreachable, and the sweep process will remove them.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2020-02-21-nodejs-memory-management/95A359CC-5C9E-49AF-89A8-D828AB1F9F45.png",
        "alt": "Mark sweep"
      }}></img></p>
    <p>{`The GC stops the process, hence not performant.`}</p>
    <h2>{`Load Test`}</h2>
    <p>{`To perform a load test, let's create a Node.js server.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var http = require('http'); 
var heapdump = require('heapdump'); 

function Bigata()
{
    var mem = Array(1000000).join('a'); 
}

var leak = []; 

var server = http.createServer(function(request, response) {
    
    if(request.uri = '/') { 
        // leak.push(new Biglata);
        response.writeHead(200, { "Content—Type": "text/html" }); 
        response.end('Hello World!');
    };
});

server.listen(3000);
`}</code></pre>
    <p>{`We can then profile the resulting web page in Chrome DevTools.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2020-02-21-nodejs-memory-management/4F1F93BE-35AC-40EC-8910-6E39634DD9F1.png",
        "alt": "Load test console"
      }}></img></p>
    <h2>{`Trace`}</h2>
    <p>{`To trace the server, run the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`node --trace_gc server.js
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      